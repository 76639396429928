/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "./assets/scss/customtable.scss";
.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #2f3542;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
}

::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
}

.btn.blue{
    color: white;
    background-color: #4B89DC;
}

.btn.red{
    color: white;
    background-color: #DB4455;
}

.btn.gray{
    color: black;
    background-color: #e2e2e2;
}

.btn.search{
    background-image: url(assets/images/magnifying-glass2.png);
}


.filebox input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
}

.filebox label {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    vertical-align: middle;
    background-color: #999999;
    cursor: pointer;
    height: 35px;
    margin-left: 10px;
    margin-bottom: 0;
    border-radius: 3px;
}

.judgeBox .reviewBox .reviewList .review .title{
    display: flex;
    justify-content: left;
    align-items: center;
}

.judgeBox .reviewBox .reviewList .review .title .deploy{
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #cccccc;
    margin-left: 10px;
}

.judgeBox .reviewBox .reviewList .review .comment{
    margin-bottom: 10px;
}


.cdk-overlay-container{
    z-index: 1070 !important;
}

.ant-btn-primary{
    background-color: #7165FD;
    border-color: #7165FD;
}

.login-card .login-main {
    width: 75%;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 0 0;
    margin: 0 auto;
    background-color: #fff;
}

table thead tr th,
table tbody tr td,
.table th,
.table td{
    text-align: left;
}

span.red{
    color : red;
}

textarea{
    border-radius: 5px;
}