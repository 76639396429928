
/* customeTable */
.customTable .tableHedaer{
    border-bottom: 2px solid #ecf3fa;
    color: #2b2b2b;
    font-weight: bold;
    text-align: center;
    /* background-color: #EDEDED; */
}

.customTable .tableHedaer,
.customTable .tableTbody{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.customTable .tableHedaer .tr,
.customTable .tableTbody .tr{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.customTable .tableHedaer .tr .td,
.customTable .tableTbody .tr .td{
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
}

.customTable .tableHedaer .tr .td div,
.customTable .tableTbody .tr .td div{
    width: 100%; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customTable .tableHedaer .tr .td.name,
.customTable .tableTbody .tr .td.name{
    width: 10%;
}

.customTable .tableHedaer .tr .td.source,
.customTable .tableTbody .tr .td.source{
    width: 10%;
}

.customTable .tableHedaer .tr .td.explain,
.customTable .tableTbody .tr .td.explain{
    width: 49%;
}

.customTable .tableHedaer .tr .td.button,
.customTable .tableTbody .tr .td.button{
    width: 31%;
}